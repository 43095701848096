import React from "react";
import { MDDropdown } from "../reusable/common/MDDropdown";
import { useAuth } from "hooks/AuthProvider";

const Profile = () => {
  const { logout } = useAuth();
  const {
    user: {
      employee: { user },
    },
  } = useAuth();

  return (
    <MDDropdown
      buttonContent={
        <div className="relative cursor-pointer inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-orange-600 dark:hover:bg-gray-500 dark:focus:ring-gray-500  rounded-full dark:bg-gray-600">
          <span className="font-medium text-white dark:text-gray-300">
            {user?.firstName.charAt(0).toUpperCase()}
          </span>
        </div>
      }
      dropdownContent={
        <div className="shadow-lg absolute right-0 w-60 bg-white border border-gray-300  dark:border-gray-500  divide-y divide-gray-100 rounded-lg  dark:bg-gray-700 dark:divide-gray-600">
          <div className="flex w-full">
            <div className="h-12 w-12 grid place-items-center border  m-1">
              {user?.profileImage?.length > 0 ? (
                <img
                  className="w-30 h-30 rounded"
                  src={user?.profileImage?.logoUrl?.[0]}
                  alt="Large avatar"
                />
              ) : (
                user?.firstName.charAt(0).toUpperCase()
              )}
            </div>
            <div className="p-2 text-sm text-gray-900 dark:text-white w-[70%] ">
              <div>{user?.firstName}</div>
              <div className="font-medium truncate">{user?.email}</div>
            </div>
          </div>
          <div className="py-1" onClick={logout}>
            <span className="block px-4 py-2 text-sm text-red-700 dark:text-red-300 hover:bg-gray-100 dark:hover:bg-gray-600  dark:hover:text-red-400 hover:cursor-pointer">
              Sign out
            </span>
          </div>
        </div>
      }
    />
  );
};

export default Profile;
