import React, { useState } from "react";
import { Formik, Form as FormikForm } from "formik";
import Fields from "./fields";
import * as Yup from "yup";

// Define a type for your form fields (replace 'any' with your actual type)
interface FormField {
  hide: any;
  type: string;
  key: string;

  // Define the properties of your form fields here
}

interface FormProps {
  children: React.ReactNode;
  schema?: any[][];
  validation?: Yup.AnyObject;
  onSubmit: (values: any) => void;
  state: any;
}

const MDForm: React.FC<FormProps> = ({
  children,
  validation,
  schema,
  onSubmit,
  state,
}) => {
  const [validateOnChange, setValidateOnChange] = useState(false);

  return (
    <div>
      <Formik
        initialValues={state}
        validateOnChange={validateOnChange}
        validationSchema={validation}
        validateOnBlur={false}
        onSubmit={(values) => {
          onSubmit && onSubmit(values);
        }}
        enableReinitialize>
        {({ handleSubmit, handleReset, ...props }) => {
          return (
            <>
              <FormikForm
                id={state.formId}
                onSubmit={(event) => {
                  setValidateOnChange(true);
                  handleSubmit(event);
                }}
                onReset={handleReset}>
                {(schema || []).map((row, index) => {
                  const visibleFields = (row || []).filter(
                    (field: FormField) => !field.hide
                  );
                  return (
                    <div key={"md-form-row" + index} className="mb-4">
                      <Fields
                        row={visibleFields}
                        {...props}
                        key={"md-form-row" + index + 10}
                      />
                    </div>
                  );
                })}
                {children}
              </FormikForm>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default MDForm;
