import { toast } from "react-toastify";

const messageId = 13;
export const notifier = {
  error: (msg) =>
    toast(msg, {
      type: "error",
      position: "top-right",
      toastId: messageId,
    }),
  success: (msg, position = "top-right") =>
    toast(msg, {
      type: "success",
      position,
      toastId: messageId,
    }),
  info: (msg, position = "top-right") =>
    toast(msg, {
      type: "info",
      position,
      toastId: messageId,
    }),
};
