import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { WindProvider } from "./midas-ui";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import SplashScreen from "./components/SplashScreen";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
// import { subscribeUser } from './subscription';

const AppSuspense = () => (
  <Suspense fallback={<SplashScreen />}>
    <WindProvider usePreferences>
      <App />
    </WindProvider>
  </Suspense>
);

const rootElement = document.getElementById("root");
createRoot(rootElement).render(<AppSuspense />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
// subscribeUser();
serviceWorker.register();
