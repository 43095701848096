import { SERVERS, VERBS } from "../apis/request";

export const loginState: LM.IStateSchema = {
  entity: "Login",
  actions: {
    create: {
      value: true,
      // starts with synch states or states without side effects
      api: {
        verb: VERBS.post,
        endpoint: "/auth/unlock?companyId=:companyId",
        server: SERVERS.main,
        auth: false,
      },
    },
    createGoogle: {
      value: true,
      api: {
        server: SERVERS.main,
        verb: VERBS.post,
        endpoint: "/auth/google",
        auth: false,
      },
    },
  },
};
