import { SERVERS, VERBS } from "../apis/request";

export const emailCheckState: LM.IStateSchema = {
  entity: "emailCheck",
  actions: {
    create: {
      value: true,
      // starts with synch states or states without side effects
      api: {
        verb: VERBS.post,
        endpoint: "/signin/check",
        server: SERVERS.main,
        auth: false,
      },
    },
  },
};
