/* eslint-disable no-extra-parens */
import { isBoolean, isEmpty, isEqual, map } from "lodash";
import { call, put, takeLatest } from "redux-saga/effects";
// import { apis } from '../apis';
import { request } from "../apis/request";
import { initialState } from "../redux/reducer.builder";
// import { request } from "../apis/request";
import { stateSchema } from "../redux/state.builder";
import { errorFormater } from "../utils";

const states = stateSchema();

function dispatcher(
  type: string,
  payload: Array<Object> | Object | null = null
) {
  return {
    type,
    payload,
  };
}

// function createRequest(s: string) {
//   const api = apis[s];
// if (!isEqual(api instanceof Function, true))
//   throw new Error(
//     `Could not find API details for ${s}, please add request details`
//   );
//   return api;
// }

/**
 *
 * @param endpoint a string to be formatted
 * @param params an object of parametters to be appended to the string
 * @returns formatted string
 */
function formatParamaterizedEndpoint(endpoint: string, params: any = {}) {
  /**
   * When a @url like /employee?pageNumber=:pageNumber&search=:searchKey formulated without passing the
   * @params object like {pageNumber:0, searchKey:doe}, it turns out to be an invalid url.
   * However, if the @params was not provided at all, we should send an empty string in every place of a param
   * So we replace ":paramName" placeholder with an empty string
   *
   * The result would be /employee?pageNumber=&search=
   */
  if (isEmpty(endpoint)) throw new Error(`Endpoint is not defined`);

  const paramPattern = /:\w+/g;
  const searchParams = endpoint.match(paramPattern);
  if (searchParams && searchParams.length > 0) {
    for (const searchParam of searchParams) {
      const param = searchParam.split(":")[1];
      endpoint = endpoint.replace(searchParam, params[param] || "");
    }
  }
  return endpoint;
}
// function formatParamatezedEndpoint(endpoint: string, params: Array<string>) {
//   if (isEmpty(endpoint)) throw new Error(`Endpoint is not defined`);
//   let url = endpoint;
//   forEach(
//     params || [],
//     (param: string, idx: number) => (url = replace(url, `${idx + 1}`, param))
//   );
//   console.log('===========================>', url, endpoint, params);

//   return url;
// }

function createRequest(api: LM.IAPI, params: Array<string>) {
  if (isEmpty(api)) throw new Error(`Api details are missing`);

  const { auth, ...rest } = api;

  const endpoint = formatParamaterizedEndpoint(rest.endpoint, params);

  return (data: any) =>
    request(rest.server, rest.verb, endpoint, data, {
      auth,
    });
}

function buildSaga(
  actions: LM.IConstant,
  apiData: LM.IAPI,
  followUp: string[]
) {
  return function* (props: LM.IReduxAction<object>): Generator<any, any, any> {
    const { payload, params } = props;
    // console.log('PARAM', params);
    try {
      yield put(dispatcher(actions.load, { ...initialState, loading: true }));

      const api = createRequest(apiData, params);
      const response = yield call(api, payload);
      if (
        isEmpty(response) ||
        !response.success ||
        !isEmpty(response.data && response.data.errors) ||
        response instanceof Error ||
        response?.error
      ) {
        let err = response;
        if (response?.message) {
          err = response?.message;
        } else if (response?.error) {
          err = response.error;
        } else if (response?.errors) {
          err = {};
          map(Object.keys(response?.errors) || [], (s: any) => {
            let dt = response?.errors[s];
            err["field"] = dt["param"];
            err["message"] = dt["msg"];
          });
        }

        return yield put(
          dispatcher(actions.error, {
            ...response,
            errors: errorFormater(err),
          })
        ) as any;
      }
      if (!isEmpty(followUp)) {
        yield put(dispatcher(actions.success, response));
        yield put(dispatcher(actions.data, response.data));

        // dispatch in sequence starting with synch states
        for (const flup of followUp) {
          yield* sequentialDispatcher(
            flup,
            isBoolean(response.data) ? payload : response.data,
            payload
          );
        }

        return;
      }

      yield put(dispatcher(actions.success, response));
      return yield put(dispatcher(actions.data, response.data));
    } catch (error) {
      return yield put(
        dispatcher(actions.error, {
          ...(error as {}),
          errors: errorFormater(error),
        })
      );
    }
  };
}

function* sequentialDispatcher(type: string, data: object, req: object) {
  yield put(dispatcher(type, { ...req, ...data }));
}
export function combinedSagas() {
  const sagas: LM.IBuiltSaga = {};

  // filter out dumb states
  const filteredStates: LM.IBuildState = {};

  map(Object.keys(states) || [], (s: string) => {
    const state = states[s];
    if (!isEqual(state.hasNoSideEffect, true)) filteredStates[s] = state;
  });

  return map(Object.keys(filteredStates) || [], (s: string) => {
    const state = filteredStates[s];
    sagas[s] = buildSaga(state.constants, state.api, state.onSuccess!);
    return takeLatest(state.constants.request, sagas[s]);
  });
}
