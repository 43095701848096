import React, { useState, useRef, useEffect, ReactNode } from "react";

interface MDDropdownProps {
  buttonContent: ReactNode;
  dropdownContent: ReactNode;
  direction?: "top" | "bottom";
  topPosition?: string;
}

export const MDDropdown: React.FC<MDDropdownProps> = ({
  buttonContent,
  dropdownContent,
  direction = "bottom",
  topPosition = "0",
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (!dropdownRef.current?.contains(event.target as Node)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const dropdownStyle: React.CSSProperties = {
    display: dropdownVisible ? "block" : "none",
    position: "absolute",
    top: direction === "top" ? topPosition : "auto",
    bottom: "auto",
    right: 0,
    zIndex: 50,
  };

  return (
    <div className="relative inline-block text-left">
      <button
        onClick={toggleDropdown}
        className="inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 bg-white dark:text-white focus:ring-gray-50 dark:bg-transparent"
        type="button"
        id="dropdownMenuIconButton"
        aria-haspopup="true"
        aria-expanded={dropdownVisible ? "true" : "false"}
        style={{ zIndex: dropdownVisible ? 51 : "auto" }}>
        {buttonContent}
      </button>

      {dropdownVisible && (
        <div
          style={dropdownStyle}
          className="origin-top-right dark:bg-gray-700 dark:divide-gray-600 absolute right-0 w-44 bg-white divide-y divide-gray-100 rounded-lg shadow z-50"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="dropdownMenuIconButton"
          ref={dropdownRef}>
          {dropdownContent}
        </div>
      )}
    </div>
  );
};
