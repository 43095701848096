import { SERVERS, VERBS } from "../apis/request";

export const loanConfigState: LM.IStateSchema = {
  entity: "LoanConfig",
  actions: {
    create: {
      value: true,
      // starts with synch states or states without side effects
      api: {
        verb: VERBS.post,
        endpoint: "/loan/configs",
        server: SERVERS.loan,
        auth: true,
      },
    },
    list: {
      value: true,
      // starts with synch states or states without side effects
      api: {
        verb: VERBS.get,
        endpoint: "/loan/configs?search=:search",
        server: SERVERS.loan,
        auth: true,
      },
    },
    delete: {
      value: true,
      // starts with synch states or states without side effects
      api: {
        server: SERVERS.loan,
        verb: VERBS.delete,
        endpoint: "/loan/configs/:loanConfigId",
        auth: true,
      },
    },
  },
};
