import { SERVERS, VERBS } from "../apis/request";

export const employeeState: LM.IStateSchema = {
  entity: "Employee",
  actions: {
    view: {
      value: true,
      // starts with synch states or states without side effects
      api: {
        verb: VERBS.get,
        endpoint: "/employee/identification?employeeId=:employeeId",
        server: SERVERS.main,
        auth: true,
      },
    },
    // list: {
    //   value: true,
    //   // starts with synch states or states without side effects
    //   api: {
    //     verb: VERBS.get,
    //     endpoint: '/employee',
    //     server: SERVERS.main,
    //     auth: true
    //   }
    // }
  },
};
