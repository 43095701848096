import { useEffect } from "react";
import { useAuth } from "../hooks/AuthProvider";
import Preloader from "./Preloader";

interface IPrivateRoute {
  children: React.ReactElement;
}
const PrivateRoute = ({ children }: IPrivateRoute) => {
  const { isLoading, isAuthenticated, logout } = useAuth();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      // redirect user to the auth page
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated]);

  if (isLoading || !isAuthenticated) return <Preloader />;

  return children;
};

export default PrivateRoute;
