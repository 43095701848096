import React, { InputHTMLAttributes, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import classNames from "classnames";

export interface InputProps extends React.ComponentPropsWithRef<"input"> {
  valid?: boolean;
  errorMessage?: string;
  label?: string;
  labelClassName?: string;
  errors?: any;
  className?: string;
  type?: string;
  inputClassName?: string;
  handleChange?: () => void;
  handleBlur?: () => void;
}

const MDInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ ...props }, ref) => {
    const [viewPassword, setViewPassword] = useState(false);
    const ViewPasswordIcon = viewPassword
      ? AiOutlineEyeInvisible
      : AiOutlineEye;
    return (
      <div>
        <div className="relative">
          <input
            ref={ref}
            className={classNames(
              "block px-2.5  pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-orange-600 focus:outline-none focus:ring-0 focus:border-orange-600 peer mt-7",
              props.inputClassName
            )}
            placeholder=" "
            {...props}
            type={
              props.type === "password"
                ? viewPassword
                  ? "text"
                  : "password"
                : props.type
            }
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          />
          <label
            htmlFor={props.id}
            className={classNames(
              "absolute text-sm text-gray-500 dark:bg-gray-700  dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2  peer-focus:scale-75 peer-focus:-translate-y-4 left-1",
              props.labelClassName
            )}>
            {props.label}
          </label>
          <span
            className="absolute inset-y-0 right-2 flex items-center cursor-pointer"
            onClick={() => setViewPassword((isVisible) => !isVisible)}>
            {props.type === "password" && (
              <ViewPasswordIcon size={20} className="dark:text-gray-900" />
            )}
          </span>
        </div>
        {props.name && props.errors[props.name] && (
          <p
            id="outlined_error_help"
            className="mt-2 text-xs text-red-600 dark:text-red-400">
            {props.errors[props.name]}
          </p>
        )}
      </div>
    );
  }
);

MDInput.displayName = "MDInput";

export default MDInput;
