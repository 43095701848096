import { Transition } from "../../../midas-ui";
import React, { useState } from "react";
// import { NavLink, Route } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import * as Icons from "../../icons";
import { DropdownIcon } from "../../icons";
import MenuItem from "./MenuItem";

const Icon = ({ icon, ...props }) => {
  const Icon = Icons[icon];
  return <Icon {...props} />;
};

const SidebarSubmenu = ({ route }) => {
  const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(true);

  const handleDropdownMenuClick = () => {
    setIsDropdownMenuOpen(!isDropdownMenuOpen);
  };

  return (
    <li className="relative px-6 py-3" key={route.name}>
      <button
        className="inline-flex items-center justify-between w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
        onClick={handleDropdownMenuClick}
        aria-haspopup="true">
        <span className="inline-flex items-center">
          <Icon className="w-5 h-5" aria-hidden="true" icon={route.icon} />
          <span className="ml-4">{route.name}</span>
        </span>
        <DropdownIcon className="w-4 h-4" aria-hidden="true" />
      </button>
      <Transition
        show={isDropdownMenuOpen}
        enter="transition-all ease-in-out duration-300"
        enterFrom="opacity-25 max-h-0"
        enterTo="opacity-100 max-h-xl"
        leave="transition-all ease-in-out duration-300"
        leaveFrom="opacity-100 max-h-xl"
        leaveTo="opacity-0 max-h-0">
        <ul
          className="py-2 mt-2 space-y-2 overflow-hidden text-sm font-medium"
          aria-label="submenu">
          {route.routes.map((r, rKey) => (
            <MenuItem
              key={rKey}
              name={r.name}
              path={r.path}
              icon={r.icon}
              exact={r.exact}
            />
          ))}
        </ul>
      </Transition>
    </li>
  );
};

export default SidebarSubmenu;
