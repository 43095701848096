import Logo from "../assets/img/Huza_logo_orange.png";

const Preloader = ({ message = "Loading...." }) => (
  <div className="flex items-center flex-col justify-center w-full h-screen animate-pulse p-6 text-lg font-medium text-gray-600 dark:text-gray-400 dark:bg-gray-900">
    <img
      aria-hidden="true"
      className="object-cover w-24"
      src={Logo}
      alt="Office"
    />
    <p className="text-sm mt-2">{message}</p>
  </div>
);

export default Preloader;
