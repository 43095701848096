import { Badge, Dropdown, DropdownItem } from "../../midas-ui";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SidebarContext } from "../../context/SidebarContext";
import { BellIcon, MenuIcon, SpinnerIcon } from "../icons";
import ModeSwitcher from "./Sidebar/RightSidebar/ModeSwitcher";
import { states } from "../../constants/index";
import { Span } from "wildcards";
import Profile from "./Profile";

const Header = () => {
  const { toggleSidebar } = useContext(SidebarContext);
  const [isNotificationsMenuOpen, setIsNotificationsMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const {
    viewNotif: { data: notifications, loading },
    // @ts-expect-error
  } = useSelector((state) => state);
  const handleNotificationsClick = useCallback(() => {
    setIsNotificationsMenuOpen((open) => !open);
  }, []);
  useEffect(() => {
    dispatch({
      type: states.viewNotif.constants.request,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const allNotifs =
    (notifications?.failedDisburses || 0) + (notifications?.pendingLoans || 0);
  return (
    <header className="z-40 py-4 border-b-2 shadow-sm border-[#E1E4EB] bg-white shadow-bottom dark:bg-gray-800 dark:border-b-0">
      <div className="container flex items-center justify-between h-full px-6 mx-auto dark:text-white">
        {/* <!-- Mobile hamburger --> */}
        <button
          className="p-1 mr-5 -ml-1 rounded-md lg:hidden focus:outline-none focus:shadow-outline-[#d16438]"
          onClick={toggleSidebar}
          aria-label="Menu">
          <MenuIcon className="w-6 h-6 " aria-hidden="true" />
        </button>
        {/* <!-- Search input --> */}
        <div className="flex justify-center flex-col flex-1"></div>
        <ul className="flex items-center flex-shrink-0 space-x-6">
          {/* <!-- Theme toggler --> */}
          <li className="relative">
            <ModeSwitcher />
          </li>
          {/* <!-- Notifications menu --> */}
          <li className="relative">
            {loading ? (
              <SpinnerIcon />
            ) : (
              allNotifs > 0 && (
                <>
                  <button
                    className="relative align-middle rounded-md focus:outline-none focus:shadow-outline-[#d16438]"
                    onClick={handleNotificationsClick}
                    aria-label="Notifications"
                    aria-haspopup="true">
                    <BellIcon className="w-7 h-7" aria-hidden="true" />
                    <span className="absolute dark:text-white top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
                      {allNotifs}
                    </span>
                  </button>

                  <Dropdown
                    align="right"
                    isOpen={isNotificationsMenuOpen}
                    onClose={() => setIsNotificationsMenuOpen(false)}>
                    {notifications?.failedDisburses > 0 && (
                      <DropdownItem
                        tag="a"
                        href="/app/loans?disbursementStatus=FAILED"
                        className="justify-between">
                        <Span className=" text-black bold dark:!text-white">
                          Failed disbursement
                        </Span>
                        <div className=" w-7 h-7">
                          <Badge type="danger">
                            {notifications?.failedDisburses}
                          </Badge>
                        </div>
                      </DropdownItem>
                    )}
                    {notifications?.pendingLoans > 0 && (
                      <DropdownItem
                        tag="a"
                        href="/app/loans?status=PENDING"
                        className="justify-between">
                        <Span className=" text-black bold dark:!text-white">
                          New loan request
                        </Span>
                        <Badge type="danger">
                          {notifications?.pendingLoans}
                        </Badge>
                      </DropdownItem>
                    )}
                  </Dropdown>
                </>
              )
            )}
          </li>
          {/* <!-- Profile menu --> */}
          <li className="relative">
            <Profile />
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
