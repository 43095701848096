import React, { useEffect, useContext, useState } from "react";
import { createPortal } from "react-dom";
import Backdrop from "./Backdrop";
import Transition from "./Transition";
import FocusLock from "react-focus-lock";
import { Icon } from "components/Icon";
import classNames from "classnames";

import { ThemeContext } from "../context/ThemeContext";

export interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Function executed when the dropdown is closed
   */
  onClose: () => void;
  /**
   * Defines if the modal is open
   */
  isOpen: boolean;
}

const Modal = React.forwardRef<HTMLDivElement, ModalProps>(
  function Modal(props, ref) {
    const { children, onClose, isOpen, className, ...other } = props;

    const {
      theme: { modal },
    } = useContext(ThemeContext);

    const baseStyle = modal.base;
    const cls = classNames(baseStyle, className);

    function handleEsc(e: KeyboardEvent) {
      if (e.key === "Esc" || e.key === "Escape") {
        onClose();
      }
    }

    useEffect(() => {
      document.addEventListener("keydown", handleEsc, { capture: true });
      return () => {
        document.removeEventListener("keydown", handleEsc);
      };
    });

    const [mounted, setMounted] = useState(false);

    useEffect(() => {
      setMounted(true);
    }, []);

    const modalComponent = (
      <Transition show={isOpen}>
        <>
          <Transition
            enter="transition ease-out duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Backdrop onClick={onClose}>
              <Transition
                enter="transition ease-out duration-150"
                enterFrom="opacity-0 transform translate-y-1/2"
                enterTo="opacity-100"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0  transform translate-y-1/2">
                <div
                  className={cls}
                  role="dialog"
                  onClick={(e) => e.stopPropagation()}
                  ref={ref}
                  {...other}>
                  <FocusLock returnFocus>
                    <header className="flex justify-end mr-6">
                      <button
                        className="absolute mt-2 items-center justify-start w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
                        aria-label="close"
                        onClick={onClose}>
                        <Icon
                          className="w-6 h-6"
                          aria-hidden="true"
                          icon="CloseIcon"
                        />
                      </button>
                    </header>
                    {children}
                  </FocusLock>
                </div>
              </Transition>
            </Backdrop>
          </Transition>
        </>
      </Transition>
    );

    return mounted ? createPortal(modalComponent, document.body) : null;
  }
);

export default Modal;
