import { stateSchema } from "../redux/state.builder";

export enum NOTIFICATION_TYPE {
  ERROR = "error",
  SUCCESS = "success",
  INFO = "info",
}

export enum STATUSES {
  PENDING = "pending",
  COMPLETED = "completed",
  ACTIVE = "active",
  REJECTED = "rejected",
}
export const states = stateSchema();
