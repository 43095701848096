import { combineReducers } from "redux";
import { ctxReducer } from "./ctx.reducer";
import { combinedReducers } from "./reducer.builder";

const combined = combinedReducers();

const reducers = combineReducers({
  ctx: ctxReducer,
  ...combined,
});

export default reducers;
