import React, { useContext } from "react";
import classNames from "classnames";
import { ThemeContext } from "../context/ThemeContext";

export interface BadgeProps extends React.HTMLAttributes<HTMLSpanElement> {
  /**
   * The type of the badge
   */
  type?:
    | "success"
    | "danger"
    | "warning"
    | "neutral"
    | "primary"
    | "active"
    | "cancel"
    | "actionRetry"
    | "approveActive"
    | "noAction";
}

const Badge = React.forwardRef<HTMLSpanElement, BadgeProps>(
  function Badge(props, ref) {
    const { className, children, type = "primary", ...other } = props;

    const {
      theme: { badge },
    } = useContext(ThemeContext);

    const baseStyle =
      type !== "noAction" && type !== "approveActive" && type !== "actionRetry"
        ? badge.base
        : "";
    const typeStyle = {
      success: badge.success,
      danger: badge.danger,
      warning: badge.warning,
      neutral: badge.neutral,
      primary: badge.primary,
      active: badge.active,
      cancel: badge.cancel,
      actionRetry: badge.actionRetry,
      noAction: badge.noAction,
      approveActive: badge.approveActive,
    };

    const cls = classNames(baseStyle, typeStyle[type], className);

    return (
      <span className={cls} ref={ref} {...other}>
        {children}
      </span>
    );
  }
);

export default Badge;
