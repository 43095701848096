import Logo from "../assets/img/Huza_logo_orange.png";

const SplashScreen = ({ message = "Loading...." }) => (
  <div className="w-screen h-screen">
    <div className="flex items-center flex-col justify-center w-full h-full animate-pulse p-6 text-lg font-medium text-gray-600 dark:text-gray-400 dark:bg-gray-900">
      <img
        aria-hidden="true"
        className="object-cover w-24"
        src={Logo}
        alt="Office"
      />
      <p className="text-sm mt-2">{message}</p>
    </div>
  </div>
);

export default SplashScreen;
