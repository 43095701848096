// import { applyMiddleware, compose, createStore } from 'redux';
// import logger from 'redux-logger';
// import { persistReducer, persistStore } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
// import createSagaMiddleware from 'redux-saga';
// import thunkMiddleware from 'redux-thunk';
// import reducers from '../redux';
// import rootSaga from '../sagas';
// import { loadFromLocalStorage } from '../utils';

// const sagaMiddleware = createSagaMiddleware();
// const ctx = loadFromLocalStorage('ctx') || {};

// const persistConfig = {
//   key: 'root',
//   storage,
//   whitelist: ['createLogin']
// };
// const persistedReducer = persistReducer(persistConfig, reducers);

// const store = createStore(
//   persistedReducer,
//   { ctx },
//   compose(
//     applyMiddleware(thunkMiddleware, sagaMiddleware, logger),
//     window.devToolsExtension
//       ? window.devToolsExtension()
//       : function (f) {
//           return f;
//         }
//   )
// );

// sagaMiddleware.run(rootSaga);
// let persistor = persistStore(store);

// export { store, persistor };
// // export default store;

import { applyMiddleware, compose, createStore } from "redux";
import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";
import thunkMiddleware from "redux-thunk";
import reducers from "../redux";
import rootSaga from "../sagas";
import { loadFromLocalStorage } from "../utils";

const sagaMiddleware = createSagaMiddleware();
const ctx = loadFromLocalStorage("ctx") || {};

/**
 * Log store when development
 */
// const isDev = process.env.NODE_ENV === 'development';
const store = createStore(
  reducers,
  { ctx },
  compose(
    applyMiddleware(thunkMiddleware, sagaMiddleware, logger),
    window.devToolsExtension
      ? window.devToolsExtension()
      : function (f) {
          return f;
        }
  )
);

sagaMiddleware.run(rootSaga);

export default store;
