import React, { useMemo, useState } from "react";

interface IContextProps {
  isSidebarOpen: boolean;
  toggleSidebar: any;
  closeSidebar: any;
}

interface AuxProps {
  children: React.ReactNode;
}
// create context
export const SidebarContext = React.createContext({} as IContextProps);

export const SidebarProvider = ({ children }: AuxProps) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  function closeSidebar() {
    setIsSidebarOpen(false);
  }

  const value = useMemo(
    () => ({
      isSidebarOpen,
      toggleSidebar,
      closeSidebar,
    }),
    [isSidebarOpen, toggleSidebar]
  );

  return (
    <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
  );
};
