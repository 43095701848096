import { isEmpty, isInteger } from "lodash";

/**
 * Call this function to load state from browser local storage
 */
export function loadFromLocalStorage(key) {
  try {
    const serializedSate = localStorage.getItem(key);
    if (isEmpty(serializedSate)) {
      return undefined;
    }
    if (key === "userToken") return serializedSate;
    return JSON.parse(serializedSate);
  } catch (error) {
    return error;
  }
}

/**
 * Call this function to save state into browser local storage
 * @param {*} state
 */
export function saveIntoLocalStorage(key, value) {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    // console.log('saveState error', error);
    return null;
  }
}

export function errorFormater(error) {
  if (typeof error === "string") {
    return [{ message: error }];
  }

  if (error instanceof Array) {
    return error;
  }

  if (error instanceof Object) {
    return [
      {
        field: error.field,
        message: error.message,
      },
    ];
  }
  return null;
}

// export function buildSelectOptions(data, key, value) {
//   return map(data || [], (d) => ({
//     label: key && d[key] || d.label || d.name,
//     value: value && d[value] || d.id
//   }));
// }

export const getCurrencySymbol = (locale, currency) =>
  (0)
    .toLocaleString(locale, {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, "")
    .trim();

export const amountFormatter = (amount, currency = "RWF") => {
  if (!amount) return 0;

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    minimumFractionDigits: 0,
  });
  return formatter.format(amount);
};

const number_system = [
  {
    divisor: 1.0e12,
    symbol: "T",
  },
  {
    divisor: 1.0e9,
    symbol: "B",
  },
  {
    divisor: 1.0e6,
    symbol: "M",
  },
  {
    divisor: 1.0e3,
    symbol: "K",
  },
];

/**
 *
 * @param {*} number
 * @returns a shortened number to its thousands. example 1000 will return 1K, 10000000 will return 10M
 */
export const shortenNumber = (number) => {
  let x = Math.abs(Math.round(number));
  for (const map of number_system) {
    if (x >= map.divisor) {
      return x / map.divisor + " " + map.symbol;
    }
  }
  return number.toFixed(2);
};

export const getUserPages = (permissions = [], isCompanySuperAdmin = true) => {
  // const allPages = ['/dashboard', '/loans', '/settlements', '/loan-configurations']
  const userPages = ["dashboard", "loans"];
  const configPermissions = permissions.filter((p) =>
    p.rule?.key.includes("_loans_configurations")
  );
  if (configPermissions.length > 0 || isCompanySuperAdmin) {
    userPages.push(
      ...["settlements", "loan-configurations", "settlements/:companyId"]
    );
  }
  return userPages;
};
