import { companyState } from "./company.state";
import { dashboardCard } from "./dashboardCard.state";
import { emailCheckState } from "./email.check.state";
import { employeeState } from "./employee.state";
import { fundState } from "./fund.state";
import { loanConfigState } from "./loan.config.state";
import { loanState } from "./loan.state";
import { loginState } from "./login.state";
import { notifState } from "./notif.state";
import { settlementState } from "./settlement.state";
import { loanCard } from "./loanCard.state";

// ADD API object onto these states
export const STATES: LM.IStateSchema[] = [
  loginState,
  emailCheckState,
  loanState,
  employeeState,
  dashboardCard,
  loanConfigState,
  notifState,
  fundState,
  settlementState,
  companyState,
  loanCard,
];
