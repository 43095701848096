import React from "react";
import MDInput from "./MDInput";

interface Field {
  type: string;
}

interface FieldsProps {
  row: Field[];
  key: any;
}

const Fields: React.FC<FieldsProps> = ({ row, key, ...rest }) => {
  const getFieldView = ({ type, ...props }: Field, index: number) => {
    switch (type) {
      case "":
      default:
        return (
          <MDInput
            {...props}
            type={type}
            key={"md-form-field" + index}
            {...rest}
          />
        );
    }
  };
  return (
    <>
      {(row || []).map((field, index) => {
        return getFieldView(field, index);
      })}
    </>
  );
};

export default Fields;
