import { useAuth } from "hooks/AuthProvider";
import { useContext, useEffect } from "react";
import { renderRoutes } from "react-router-config";
import { useLocation } from "react-router-dom";
import { SidebarContext } from "../../context/SidebarContext";
import PrivateRoute from "../PrivateRoute";
import Header from "./Header";
import Main from "./Main";
import Sidebar from "./Sidebar";
import DesktopRightSidebar from "./Sidebar/RightSidebar";

interface IDashboardLayout {
  route: any;
  match: any;
}
const DashboardLayout = ({ route, match }: IDashboardLayout) => {
  const { isSidebarOpen, closeSidebar } = useContext(SidebarContext);
  const auth = useAuth();
  const isPesachoiceAdmin = auth.userPages.includes("loan-configurations");
  let location = useLocation();

  useEffect(() => {
    closeSidebar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <PrivateRoute>
      <div
        className={`flex h-screen bg-grayishBlue dark:bg-gray-900 ${
          isSidebarOpen && "overflow-hidden"
        }`}>
        <Sidebar />
        <div className="flex flex-col flex-1 w-full">
          <Header />
          <Main>{renderRoutes(route.routes, { auth })}</Main>
        </div>
        {/* {isPesachoiceAdmin && location.pathname.includes("/loans") && (
          <DesktopRightSidebar />
        )} */}
      </div>
    </PrivateRoute>
  );
};
export default DashboardLayout;
