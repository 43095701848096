import { Provider } from "react-redux";
import { renderRoutes } from "react-router-config";
import { Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// import { PersistGate } from 'redux-persist/integration/react';
import { SidebarProvider } from "./context/SidebarContext";
import { history } from "./history";
import { AuthProvider } from "./hooks/AuthProvider";
import { routes } from "./router";
import store from "./store/index";

const App = () => {
  return (
    <Router history={history}>
      <Provider store={store}>
        <AuthProvider>
          {/* <PersistGate loading={null} persistor={persistor}> */}
          <SidebarProvider>
            <ToastContainer />
            {renderRoutes(routes)}
          </SidebarProvider>
          {/* </PersistGate> */}
        </AuthProvider>
      </Provider>
    </Router>
  );
};

export default App;
