import { SERVERS, VERBS } from "../apis/request";

//The get all loans endpoint is to long to maintain.🦾
let allLoansEndpoint = "/loans";
allLoansEndpoint += "?type=:type&status=:status&companyId=:companyId";
allLoansEndpoint += "&dateFrom=:dateFrom&dateTo=:dateTo";
allLoansEndpoint += "&page=:page&pageSize=:pageSize";

export const loanState: LM.IStateSchema = {
  entity: "Loan",
  actions: {
    create: {
      value: true,
      // starts with synch states or states without side effects
      api: {
        verb: VERBS.post,
        endpoint: "/loan/request",
        server: SERVERS.loan,
        auth: true,
      },
    },
    list: {
      value: true,
      // starts with synch states or states without side effects
      api: {
        server: SERVERS.loan,
        verb: VERBS.get,
        endpoint: allLoansEndpoint,
        auth: true,
      },
    },
    view: {
      value: true,
      // starts with synch states or states without side effects
      api: {
        server: SERVERS.loan,
        verb: VERBS.get,
        endpoint: "/loans/:loanId?crb=:crb",
        auth: true,
      },
    },
    update: {
      value: true,
      // starts with synch states or states without side effects
      api: {
        server: SERVERS.loan,
        verb: VERBS.patch,
        endpoint: "/loan/:loanId/reject",
        auth: true,
      },
    },
    approve: {
      value: true,
      // starts with synch states or states without side effects
      api: {
        server: SERVERS.loan,
        verb: VERBS.patch,
        endpoint: "/loan/:loanId/approve",
        auth: true,
      },
    },
    approveAmount: {
      value: true,
      // starts with synch states or states without side effects
      api: {
        server: SERVERS.loan,
        verb: VERBS.patch,
        endpoint: "/loan/:loanId/approve-full-amount",
        auth: true,
      },
    },
    retryDisburse: {
      value: true,
      // starts with synch states or states without side effects
      api: {
        server: SERVERS.loan,
        verb: VERBS.patch,
        endpoint: "/loan/:loanId/retry-approve-full-amount",
        auth: true,
      },
    },
    disburse: {
      value: true,
      // starts with synch states or states without side effects
      api: {
        server: SERVERS.loan,
        verb: VERBS.patch,
        endpoint: "/loan/:loanId/disburse",
        auth: true,
      },
    },
    settle: {
      value: true,
      // starts with synch states or states without side effects
      api: {
        server: SERVERS.loan,
        verb: VERBS.post,
        endpoint: "/loans/:loanId/settlement",
        auth: true,
      },
    },
    approveMany: {
      value: true,
      api: {
        server: SERVERS.loan,
        verb: VERBS.patch,
        endpoint: "/loan/approve-many",
        auth: true,
      },
    },
    viewDisburseStatus: {
      value: true,
      api: {
        server: SERVERS.loan,
        verb: VERBS.get,
        endpoint: "/loans/disburse-status/:loanId",
        auth: true,
      },
    },
    listCompanies: {
      value: true,
      api: {
        server: SERVERS.loan,
        verb: VERBS.get,
        endpoint: "/loan/filters",
        auth: true,
      },
    },
    checkSettlement: {
      value: true,
      api: {
        server: SERVERS.loan,
        verb: VERBS.get,
        endpoint: "/loans/check-settlements",
        auth: true,
      },
    },
  },
};
