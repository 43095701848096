const initials = {
  data: null,
};

export const ctxReducer = (state = initials, action) => {
  const { type, payload } = action;
  switch (type) {
    case "CONTEXT":
      return { ...state, data: payload };

    default:
      return state;
  }
};
