import { SERVERS, VERBS } from "../apis/request";

export const dashboardCard: LM.IStateSchema = {
  entity: "dashboardCard",
  actions: {
    create: {
      value: true,
      // starts with synch states or states without side effects
      api: {
        verb: VERBS.post,
        endpoint: "/run/query",
        server: SERVERS.loan,
        auth: true,
      },
    },
    view: {
      value: true,
      // starts with synch states or states without side effects
      api: {
        verb: VERBS.get,
        endpoint: "/run/multi-sql-query?companyId=:companyId",
        server: SERVERS.loan,
        auth: true,
      },
    },
    list: {
      value: true,
      // starts with synch states or states without side effects
      api: {
        verb: VERBS.post,
        endpoint: "/run/query",
        server: SERVERS.loan,
        auth: true,
      },
    },
    viewStatistics: {
      value: true,
      api: {
        verb: VERBS.get,
        endpoint: "/run/loan-amount-collected?companyId=:companyId&year=:year",
        server: SERVERS.loan,
        auth: true,
      },
    },
    viewRequestStatus: {
      value: true,
      api: {
        verb: VERBS.get,
        endpoint:
          "/run/loan-request-status?companyId=:companyId&year=:year&type=:type",
        server: SERVERS.loan,
        auth: true,
      },
    },
    viewNumberOfBorrowers: {
      value: true,
      api: {
        verb: VERBS.get,
        endpoint: "/run/loan-borrowers-number?companyId=:companyId&year=:year",
        server: SERVERS.loan,
        auth: true,
      },
    },
    viewAmountDisbursed: {
      value: true,
      api: {
        verb: VERBS.get,
        endpoint: "/run/loan-amount-disbursed?companyId=:companyId&year=:year",
        server: SERVERS.loan,
        auth: true,
      },
    },
  },
};
