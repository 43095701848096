import React, { useContext } from "react";
import classNames from "classnames";
import { ThemeContext } from "../context/ThemeContext";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

const ModalDivider = React.forwardRef<HTMLParagraphElement, Props>(
  function ModalDivider(props, ref) {
    const { children, className, ...other } = props;
    const {
      theme: { modalDivider },
    } = useContext(ThemeContext);

    const baseStyle = modalDivider.base;

    const cls = classNames(baseStyle, className);

    return (
      <p className={cls} ref={ref} {...other}>
        {children}
      </p>
    );
  }
);

export default ModalDivider;
