import { map, toUpper, upperCase, upperFirst } from "lodash";
import { STATES } from "../states/index";

function buildAction(state: string): LM.IConstant {
  return {
    request: `${state}_REQUEST`,
    load: `${state}_LOAD`,
    success: `${state}_SUCCESS`,
    data: `${state}_DATA`,
    error: `${state}_ERROR`,
    reset: `${state}_RESET`,
  };
}

function buildState(state: LM.IStateSchema) {
  const { actions, entity } = state;

  const built: LM.IBuildState = {}; // { add: {}}

  map(Object.keys(actions) || [], (s: keyof LM.IAction) => {
    // @ts-expect-error
    const action: LM.IActionConstant = actions[s]!;

    built[`${s}${upperFirst(entity)}`] = {
      constants: buildAction(`${toUpper(entity)}_${upperCase(s)}`),
      onSuccess: action.onSuccess,
      hasNoSideEffect: action.hasNoSideEffect,
      api: action.api,
    };
  });

  return built;
}

export function stateSchema() {
  let states: LM.IBuildState = {};

  // start working on state from here
  map(STATES, (s) => {
    states = { ...states, ...buildState(s) };
  });

  return states;
}
