import { SERVERS, VERBS } from "apis/request";

export const companyState: LM.IStateSchema = {
  entity: "Companies",
  actions: {
    list: {
      value: true,
      api: {
        verb: VERBS.post,
        endpoint: "auth/check",
        server: SERVERS.main,
        auth: false,
      },
    },
    view: {
      value: true,
      api: {
        verb: VERBS.get,
        endpoint: "companies/:id",
        server: SERVERS.main,
        auth: true,
      },
    },
  },
};
