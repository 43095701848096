import { SERVERS, VERBS } from "../apis/request";

export const loanCard: LM.IStateSchema = {
  entity: "loanCard",
  actions: {
    create: {
      value: true,
      // starts with synch states or states without side effects
      api: {
        verb: VERBS.post,
        endpoint: "/run/query",
        server: SERVERS.loan,
        auth: true,
      },
    },
    list: {
      value: true,
      // starts with synch states or states without side effects
      api: {
        verb: VERBS.post,
        endpoint: "/run/query",
        server: SERVERS.loan,
        auth: true,
      },
    },
  },
};
