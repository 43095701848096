import classNames from "classnames";

export const Span = ({ children, className, ...rest }) => {
  return (
    <span
      className={classNames("text-gray-700 dark:text-gray-700", className)}
      {...rest}>
      {children}
    </span>
  );
};
