import { SERVERS, VERBS } from "../apis/request";

export const fundState: LM.IStateSchema = {
  entity: "Fund",
  actions: {
    view: {
      value: true,
      // starts with synch states or states without side effects
      api: {
        verb: VERBS.get,
        endpoint: "/funds/balance",
        server: SERVERS.loan,
        auth: true,
      },
    },
  },
};
