import axios from "axios";
import { getUserPages } from "utils";
import { MODULE_NAME } from "../constants/utils";
import { request, SERVERS, VERBS } from "../apis/request";

export const checkUserPermissions = (employeeId, otherInfo) => {
  let result = {
    isAuthenticated: false,
    user: null,
    userPermissions: [],
    userPages: [],
    isLoading: true,
    errorMessage: null,
  };
  const urls = [
    `/employee/workinfo?employeeId=${employeeId}&withPermissions=true`,
    "entities/type/module",
  ];
  const requests = urls.map((url) =>
    request(SERVERS.main, VERBS.get, url, undefined, {
      auth: true,
    })
  );
  // check if valid token (Server Side)
  return axios
    .all(requests)
    .then((allRes) => {
      result.isLoading = false;
      const [employeeRes, modulesRes] = allRes;
      if (employeeRes.success && modulesRes.success) {
        //Check if loan modules is allowed for the company
        const loanAllowed = modulesRes.data.find(
          (mod) => mod.key === MODULE_NAME
        );
        if (loanAllowed) {
          const employeeInfo = employeeRes.data;
          const loansPermissions = employeeInfo?.permissions.filter((p) =>
            p.rule?.key.includes("resource_loans_")
          );
          if (
            loansPermissions.length > 0 ||
            employeeInfo?.authRole?.key === "admin"
          ) {
            const pages = getUserPages(loansPermissions);
            const permissionsKeys = loansPermissions.map((p) => p.rule?.key);
            result = {
              ...result,
              ...otherInfo,
              isAuthenticated: true,
              user: employeeInfo,
              userPages: pages,
              userPermissions: permissionsKeys,
            };
          } else {
            result.errorMessage =
              "You are not allowed to access loan dashboard";
          }
        } else {
          result.errorMessage =
            "Your company is not allowed to access the Loans system, Please contact Huza Customer Service Support";
        }
      } else {
        console.log({ error: "Fetching failed" });
        result.errorMessage = "Session expired";
      }
      return result;
    })
    .catch((error) => {
      console.log({ error });
      result.isLoading = false;
      result.errorMessage = "Session expired";
      return result;
    });
};
