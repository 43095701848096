import { SERVERS, VERBS } from "../apis/request";

export const settlementState: LM.IStateSchema = {
  entity: "Settlement",
  actions: {
    list: {
      value: true,
      api: {
        verb: VERBS.get,
        endpoint:
          "/loan/settlements?id=:id&status=:status&startDate=:startDate&endDate=:endDate&page=:page&pageSize=:pageSize",
        server: SERVERS.loan,
        auth: true,
      },
    },
    view: {
      value: true,
      api: {
        verb: VERBS.get,
        endpoint: "/loan/settlements/:id?page=:page&pageSize=:pageSize",
        server: SERVERS.loan,
        auth: true,
      },
    },
    update: {
      value: true,
      api: {
        verb: VERBS.patch,
        endpoint: "/loan/settlement/:id",
        server: SERVERS.loan,
        auth: true,
      },
    },
    markAsPaid: {
      value: true,
      api: {
        verb: VERBS.patch,
        endpoint: "/loan/settlement-items",
        server: SERVERS.loan,
        auth: true,
      },
    },
    generate: {
      value: true,
      api: {
        verb: VERBS.post,
        endpoint: "/loan/settlement-generate",
        server: SERVERS.loan,
        auth: true,
      },
    },
  },
};
