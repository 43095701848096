import { LoanContext } from "../../../../midas-ui";
import { useContext } from "react";

const ModeSwitcher = () => {
  const { mode, toggleMode } = useContext(LoanContext);
  return (
    <div className="flex items-end justify-between flex-row w-full relative mt-[-10px]">
      <div className="hidden md:block w-1/2">
        <p className=" font-bold dark:text-white">{mode?.toUpperCase()} Mode</p>
      </div>
      <button
        className="w-full md:w-1/2 flex justify-end items-end focus:ring-transparent"
        onClick={toggleMode}>
        <div
          className={`w-14 h-7 flex items-center rounded-full px-1 ${
            mode === "dark" ? "bg-blue-700" : "bg-gray-300"
          }`}>
          <div
            className={`bg-white w-5 h-5 rounded-full shadow-md transform ${
              mode === "dark" && "translate-x-7 duration-500 ease-in-out"
            }`}></div>
        </div>
      </button>
    </div>
  );
};
export default ModeSwitcher;
