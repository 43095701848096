import axios from "axios";
import { isEmpty, isEqual } from "lodash";
import { loadFromLocalStorage, saveIntoLocalStorage } from "../utils";

export const SERVERS = {
  main: "main",
  payroll: "payroll",
  loan: "loan",
};

export const VERBS = {
  get: "GET",
  post: "POST",
  patch: "PATCH",
  delete: "DELETE",
};

export const request = (
  server,
  method,
  url,
  data = undefined,
  { auth = false, multipart = false }
) => {
  try {
    const instance = getAxiosInstance(server, multipart, auth);

    return instance({ url, method, data })
      .then((res) => {
        const { headers } = res;
        const token = headers && headers["x-auth-token"];

        if (token) {
          saveIntoLocalStorage("userToken", token);
        }

        if (res && res.data && !res.data.success) {
          // console.log('ERROR: ', res.data);
          throw new Error(`Could not complete your request`);
        }
        return res && res.data;
      })
      .catch((error) => {
        // console.log('SIDE EFFECT ERROR: ', error);
        if (error && error.response) {
          return error.response.data;
        }
        return error;
      });
  } catch (error) {
    // console.log(error);
    return error;
  }
};

// function parseEnv(env) {
//   switch (env) {
//     case `production`:
//       return `prod`;
//     case `development`:
//       return `dev`;
//     default:
//       return 'local';
//   }
// }

function getBaseUrl(server) {
  if (isEqual(server, SERVERS.loan))
    return process.env.REACT_APP_MIDAS_LOAN_URL;

  if (isEqual(server, SERVERS.main))
    return process.env.REACT_APP_MAIN_APPLICATION_URL;

  throw new Error(`Missing server definition`);
}

function setContentType(instance, multipart = false) {
  if (isEmpty(instance)) throw new Error(`Missing server definition`);

  const contentType = multipart
    ? "application/x-www-form-urlencoded"
    : "application/json";

  instance.defaults.headers["Content-Type"] = contentType;
  instance.defaults.headers["Accept"] = "application/json";

  return instance;
}

function setAuthToken(instance) {
  try {
    const token = loadFromLocalStorage("userToken");
    if (token) {
      instance.defaults.headers["X-Auth-Token"] = token;

      return instance;
    } else {
      throw new Error(`User is not authenticated`);
    }
  } catch (error) {
    throw new Error(`User is not authenticated`);
  }
}

export function getAxiosInstance(server, multipart, auth) {
  try {
    // console.log('PROCESS', process.env);
    const baseURL = getBaseUrl(server, process.env.REACT_APP_ENV);

    let instance = axios.create({
      baseURL,
    });

    instance = setContentType(instance, multipart);

    if (auth) instance = setAuthToken(instance);

    return instance;
  } catch (error) {
    throw new Error((error || {}).message);
  }
}

const demoNotification = (token) => {
  const instance = getAxiosInstance(SERVERS.main, false, true);
  instance({ url: "/tokens/demo", method: VERBS.post, data: { token } })
    .then(() => {
      console.log("LoanManagement");
    })
    .catch((err) => {
      console.log(err.response);
    });
};
export const saveToken = (token) => {
  const instance = getAxiosInstance(SERVERS.main, false, true);
  instance({ url: "/tokens", method: VERBS.post, data: { token, type: "WEB" } })
    .then(() => {
      demoNotification(token);
    })
    .catch((err) => {
      console.log(err.response);
    });
};

function formatParamaterizedEndpoint(endpoint, params) {
  if (isEmpty(endpoint)) throw new Error(`Endpoint is not defined`);

  if (isEmpty(params)) return endpoint;

  let url = endpoint;

  // Replace URL parameters using RegEx pattern /(:[a-z]+)/g
  for (const key in params) {
    const pattern = `:${key}`;
    //@ts-ignore
    const value = params[key];

    url = url.replace(pattern, value);
  }
  return url;
}
