import React from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "../components/Layouts/DashboardLayout";

const Dashboard = React.lazy(() => import("../pages/dashboard"));
const Login = React.lazy(() => import("../pages/Login"));
const Settlements = React.lazy(() => import("../pages/invoice/Settlements"));
const SettlementDetails = React.lazy(() =>
  import("../pages/invoice/SettlementDetails")
);
const LoanConfigs = React.lazy(() => import("../pages/LoanConfigs"));
const Loans = React.lazy(() => import("../pages/Loans"));

const routePath = "/app";
const RenderComponent = ({
  component: Component,
  auth,
  routePath,
  redirectTo,
  ...otherProps
}) => {
  if (auth.isAuthenticated) {
    if (auth.userPages.includes(routePath)) {
      return <Component auth={auth} {...otherProps} />;
    }
    return <Redirect to={redirectTo || "/app/dashboard"} />;
  }
  return <Redirect to="/login" />;
};

const dashboardRoutes = [
  { path: `dashboard`, component: Dashboard },
  { path: `loans`, component: Loans },
  { path: `loan-configurations`, component: LoanConfigs },
  { path: `settlements`, component: Settlements },
  { path: `settlements/:companyId`, component: SettlementDetails },
];
export const routes = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/app/dashboard" />,
  },
  {
    path: "/login",
    exact: true,
    component: () => <Login />,
  },
  {
    path: "/app",
    component: DashboardLayout,
    routes: dashboardRoutes.map(({ path, component: Component }) => ({
      path: `${routePath}/${path}`,
      exact: true,
      component: (props) => (
        <RenderComponent component={Component} routePath={path} {...props} />
      ),
    })),
  },
];
