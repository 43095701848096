import { Icon } from "components/Icon";
import { NavLink } from "react-router-dom";

const MenuItem = ({ name, path, icon }) => {
  return (
    <li className="relative" key={name}>
      <NavLink
        exact
        to={path}
        className="rounded-sm inline-flex items-center  w-full text-sm font-semibold transition-colors duration-150 hover:bg-gray-300 hover:text-gray-600 dark:hover:text-gray-600"
        activeClassName="bg-[#d16438] text-gray-100 dark:text-gray-100">
        <div className="px-6 py-3 inline-flex items-center">
          <Icon className="w-5 h-5" aria-hidden="true" icon={icon} />
          <span className="ml-4">{name}</span>
        </div>
      </NavLink>
    </li>
  );
};

export default MenuItem;
