import jwtDecode from "jwt-decode";
import { createContext, useContext, useEffect, useState } from "react";
import { notifier } from "../utils/notifier";
import { checkUserPermissions } from "utils/checkUserPermissions";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authInfo, setAuthInfo] = useState({
    isAuthenticated: false,
    isLoading: true,
    user: null,
    userPermissions: [],
    userPages: [],
  });

  const getUser = async () => {
    // Check if token exist
    if (localStorage.getItem("userToken")) {
      const { exp, employeeId } = jwtDecode(localStorage.getItem("userToken"));
      // Validate token expiration time
      const currentTime = Date.now() / 1000;
      if (exp < currentTime) {
        // Logout
        clearLocalStorage();
        setAuthInfo((prev) => ({ ...prev, isLoading: false }));
      } else {
        const { errorMessage, ...res } = await checkUserPermissions(employeeId);
        if (errorMessage) {
          notifier.error(errorMessage);
          setIsNotAllowed(errorMessage);
        }
        if (res.isAuthenticated) {
          setAuthInfo(res);
        }
      }
    } else {
      setAuthInfo((prev) => ({ ...prev, isLoading: false }));
    }
  };

  const clearLocalStorage = () => {
    localStorage.removeItem("userToken");
  };

  const setIsNotAllowed = (message) => {
    notifier.error(message);
    clearLocalStorage();
    window.location.reload();
  };

  const logout = () => {
    clearLocalStorage();
    window.location.replace("/login");
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = { logout, ...authInfo };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
