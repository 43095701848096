/**
 * ⚠ These are used just to render the Sidebar!
 * You can include any link here, local or external.
 *
 * If you're looking to actual Router routes, go to
 * `routes/index.js`
 */
interface EnumServiceItem {
  path: string;
  icon: string;
  name: string;
  routes?: Array<EnumServiceItem>;
}

interface EnumServiceItems extends Array<EnumServiceItem> {}
// interface NestedArray extends Array<NestedArray | Atom> {}

const routes: EnumServiceItems = [
  {
    path: "dashboard", // the url
    icon: "ChartsIcon", // the component being exported from icons/index.js
    name: "Dashboard", // name that appear in Sidebar
  },
  {
    path: "loans",
    icon: "FormsIcon",
    name: "Loans",
    // routes: [
    //   // submenu
    //   {
    //     path: '/app/loans/pending',
    //     name: 'Requested',
    //     icon: 'FormsIcon'
    //   },
    //   {
    //     path: '/app/loans/approved',
    //     name: 'Pending',
    //     icon: 'FormsIcon'
    //   },
    //   {
    //     path: '/app/loans/active',
    //     name: 'Active',
    //     icon: 'FormsIcon'
    //   },
    //   {
    //     path: '/app/loans/rejected',
    //     name: 'Rejected',
    //     icon: 'FormsIcon'
    //   },
    //   {
    //     path: '/app/loans/completed',
    //     name: 'Completed',
    //     icon: 'FormsIcon'
    //   },
    //   {
    //     path: '/app/loans/cancelled',
    //     name: 'Cancelled',
    //     icon: 'FormsIcon'
    //   }
    // ]
  },
  // {
  //   path: '/app/disbursement',
  //   icon: 'CardsIcon',
  //   name: 'Disbursement'
  // },
  // {
  //   path: '/app/settlement',
  //   icon: 'MoneyIcon',
  //   name: 'Settlement'
  // },
  //   {
  //     path: '/disbursement',
  //     icon: 'CardsIcon',
  //     name: 'Reports'
  //   },
  {
    path: "settlements",
    icon: "MoneyIcon",
    name: "Settlement",
  },
  {
    path: "loan-configurations",
    icon: "PagesIcon",
    name: "Settings",
  },
];

export default routes;
